<template>
  <div>
    <top-nav :showLogo="false" :title="$t('元储存')"></top-nav>
    <div class="type-cont">
      <div class="type-item" :class="{'active':type == 1}" @click="selectType(1)">
        {{ $t('活期') }}
      </div>
      <div class="type-item" :class="{'active':type == 2}" @click="selectType(2)">
        {{ $t('定期') }}
      </div>
    </div>
    <div v-if="type == 1">
      <div class="container">
        <div class="total-number">
          <div class="title"> {{ $t('活期总存款/未激活') }}</div>
          <span class="number">{{ currentDeposit }}</span>
          <span class="unit">BUR</span>
        </div>
        <div class="other-number">
          <div class="number-item">
            <div class="title">{{ $t('聚合资产') }}</div>
            <span class="number">{{ polymerizeBalanceOf }}</span>
          </div>
        </div>
        <div class="other-number">
          <div class="number-item">
            <div class="title">{{ $t('可领取利息') }}</div>
            <span class="number">{{ currentReward }}</span>
          </div>
          <div class="number-item">
            <div class="line"></div>
            <div class="title">{{ $t('已领取利息') }}</div>
            <span class="number">{{ currentHasReward }}</span>
          </div>
        </div>
        <div v-if="currentReward > 0" class="_but" @click="onshowExtract">
          {{ $t('领取利息赎回本金') }}
        </div>
      </div>

      <div class="container">
        <div class="other-number" style="padding-bottom: 1.65rem;border-bottom: 1px solid #262526">
          <div class="number-item">
            <div class="title">{{ $t('钱包余额') }}</div>
            <span class="number">{{ burBalance }}</span>
          </div>
        </div>
        <div class="list-cont">
          <div class="list-item">
            <div class="key">{{ $t('储蓄时间') }}</div>
            <div class="value">{{ getCurrentDepositTimestamp === 'Invalid Date' ? '-' : getCurrentDepositTimestamp }}</div>
          </div>
          <div class="list-item">
            <div class="key">{{ $t('储蓄APY') }}</div>
            <div class="value2">
              {{ apy }}
            </div>
          </div>
        </div>
        <router-link to="storageCurrent">
          <div class="_but">
            {{ $t('存储') }}
          </div>
        </router-link>

      </div>
    </div>
    <div v-else-if="type == 2">
      <div class="container">
        <div class="total-number">
          <div class="title"> {{ $t('定期总存款') }}</div>
          <span class="number">{{ usrFixedHasDeposit }}</span>
          <span class="unit">BUR</span>
        </div>
        <div class="other-number">
          <div class="number-item">
            <div class="title">{{ $t('聚合资产') }}</div>
            <span class="number">{{ polymerizeBalanceOf }}</span>
          </div>
          <div class="number-item">
            <div class="title">{{ $t('钱包余额') }}</div>
            <span class="number">{{ burBalance }}</span>
          </div>
        </div>
        <div class="operate-cont">
          <div class="operate-item">
            <router-link to="/bank/storagePeriodic">
              <img class="icon" src="../../assets/image/bank/storage/storage-icon.png">
              <div>{{ $t('存储') }}</div>
            </router-link>
          </div>
          <div class="operate-item">
            <router-link to="/bank/storagePeriodicOrder">
              <img class="icon" src="../../assets/image/bank/storage/order-icon.png">
              <div>{{ $t('我的订单') }}</div>
            </router-link>
          </div>
        </div>
      </div>
<!--      <div class="container">-->
<!--        <div class="other-number" style="padding-bottom: 1.65rem;border-bottom: 1px solid #262526">-->
<!--          <div class="number-item">-->
<!--            <div class="title">{{ $t('钱包余额') }}</div>-->
<!--            <span class="number">{{ burBalance }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="list-cont" style="padding-bottom: 0">-->
<!--          <div class="list-item">-->
<!--            <div class="key">{{ $t('储蓄APY') }}</div>-->
<!--            <div class="value2">-->
<!--              8.89%-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="list-item">-->
<!--            <div class="key">{{ $t('基础利息+浮动利息') }}</div>-->
<!--            <div class="value2">-->
<!--              8.89%+8.89%-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="container">
        <div class="title" style="font-size: 1rem;color: #E7BD71;">{{ $t('基础利息') }}</div>
        <div class="list-cont">
          <div class="list-item">
            <div class="key">15天</div>
            <div class="value2">
              {{ levelPeriod[15]['rate'] * 100  }} %
            </div>
          </div>
          <div class="list-item">
            <div class="key">30天</div>
            <div class="value2">
              {{ levelPeriod[30]['rate'] * 100  }} %
            </div>
          </div>
          <div class="list-item">
            <div class="key">90天</div>
            <div class="value2">
              {{ levelPeriod[90]['rate'] * 100 }} %
            </div>
          </div>
          <div class="list-item">
            <div class="key">180天</div>
            <div class="value2">
              {{ levelPeriod[180]['rate'] * 100 }} %
            </div>
          </div>
          <div class="list-item">
            <div class="key">360天</div>
            <div class="value2">
              {{ levelPeriod[360]['rate'] * 100 }} %
            </div>
          </div>
          <div class="list-item">
            <div class="key">720天</div>
            <div class="value2">
              {{ levelPeriod[720]['rate'] * 100 }} %
            </div>
          </div>
          <div class="tips">
            {{ $t('浮动利息根据套利协议计算利') }}
          </div>
        </div>
        <router-link to="/bank/storagePeriodic">
          <div class="_but">
            {{ $t('存储') }}
          </div>
        </router-link>

      </div>

    </div>

    <van-popup v-model="showExtract">
      <div class="extract-cont">
        <div class="popup-title">
          {{ $t('领取利息赎回本金') }}
          <img @click="onshowExtract" class="close-icon" src="../../assets/image/popup/close-icon.png">
        </div>
        <div class="info-cont">
          <div class="info-item">
            <div class="key" style="color: #B5904E">
              {{ $t('提取利息') }}
            </div>
          </div>
          <div class="info-item">
            <div class="key"> {{ $t('浮动利息') }}</div>
            <div class="value">{{ apy }}</div>
          </div>
          <div class="info-item">
            <div class="key" style="color: #B5904E;padding-top: 0.8rem">
              {{ $t('赎回本金') }}
            </div>
          </div>
          <div class="info-item">
            <div class="key">{{ $t('本金') }}</div>
            <div class="value">{{ currentDeposit }} BUR</div>
          </div>
          <div class="info-item">
            <div class="key">{{ $t('可赎回数量') }}</div>
            <div class="value">{{ currentDeposit }} BUR</div>
          </div>
        </div>
        <div v-if="parseFloat(polymerizeBalanceOf) < parseFloat(currentDeposit)" class="tips">
          {{ $t('抱歉，您的聚合资产不足以完成兑换操作，请确认聚合资产是否足够后再继续操作。') }}
        </div>
        <div v-else>
          <div class="_but" style="margin-top: 1.8rem" @click="withdrawCurrent">
            {{ $t('确定') }}
          </div>
        </div>
        <div class="balance-tips">
          {{ $t('余额不足？') }}
          <router-link to="/bank/burExchange"> {{ $t('点击此处') }}</router-link>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {keepDecimal, levelPeriod, LevelPeriod} from "@/utils/tools";

export default {
  name: "storage",
  components: {TopNav},
  data() {
    return {
      type: 1,
      showExtract: false,
      account: null,
      currentDeposit: 0,
      polymerizeBalanceOf: 0,
      currentReward: 0,
      currentHasReward: 0,
      burBalance: 0,
      usrFixedHasDeposit: 0,
      getCurrentDepositTimestamp: '-',
      getCurrentDepositTimestamp1: '',
      apy: 0,
      levelPeriod: {},
    }
  },
  created() {
    this.getAccount()
    this.getInitData()
  },
  methods: {
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              this.account = res
              console.log('account----', res)
            }
          })
    },
    async getInitData() {
      let that = this;

      this.$toast.loading({
        message: '',
        duration: 0,
      });

      await this.getAccount()


      that.currentDeposit = await this.$store.dispatch('bur/getCurrentDeposit', that.account) //活期存款
      console.log('活期存款-----currentDeposit', that.currentDeposit, typeof that.currentDeposit)

      that.polymerizeBalanceOf = await this.$store.dispatch('bur/getPolymerizeBalanceOf', that.account) //聚合资产
      console.log('聚合资产-----polymerizeBalanceOf', that.polymerizeBalanceOf, typeof that.polymerizeBalanceOf)

      that.getCurrentDepositTimestamp = this.formatTimestamp(await this.$store.dispatch('bur/getCurrentDepositTimestamp', that.account)) //活期存款
      console.log('timestamp-----getCurrentDepositTimestamp', that.getCurrentDepositTimestamp)

      that.currentHasReward = await this.$store.dispatch('bur/getCurrentHasReward', that.account) //已领取利息
      console.log('已领取利息-----getUnclaimedRewards', that.currentHasReward)

      that.burBalance = await this.$store.dispatch('bur/getBurBalance', that.account) //钱包余额
      console.log('钱包余额-----getBurBalance', that.burBalance)

      that.usrFixedHasDeposit = await this.$store.dispatch('bur/getUsrFixedHasDeposit', that.account) //定期存款
      console.log('定期存款-----getUsrFixedHasDeposit', that.usrFixedHasDeposit)

      that.getCurrentDepositTimestamp1 = await this.$store.dispatch('bur/getCurrentDepositTimestamp1', that.account)

      const res = await this.$store.dispatch('bur/getCurrentReward', {amount:that.currentDeposit, timestamp: that.getCurrentDepositTimestamp1.toString()}) //定期存款
      const reward = await this.$store.dispatch('bur/getCurrentDepositReward', that.account) //reward

      const days = await this.$store.dispatch('bur/getDays', that.getCurrentDepositTimestamp1.toString()) // getDays

      this.apy = keepDecimal((1.2 * 0.0005*parseInt(days)/2.28 * 100 * that.currentDeposit).toString()) + '%'
      console.log('apy-------', this.apy)
      that.currentReward = keepDecimal((parseFloat(res + reward).toString()))
      that.levelPeriod = levelPeriod() // 定期时间对应的利息比例

      that.$toast.clear()
    },
    selectType(type) {
      this.type = type
    },
    onshowExtract() {
      this.showExtract = !this.showExtract
    },
    async withdrawCurrent() {
      let that = this;
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      await this.$store.dispatch('bur/withdrawCurrent', that.account)
          .then(res => {
            that.showExtract = false
            this.getInitData()
            console.log(res);
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.$toast.clear()
          })
    }
    ,
    formatTimestamp(timestamp) {
      if (timestamp === 0 || timestamp === '') {
        return '-'
      } else {
        const date = new Date(timestamp);
        return date.toLocaleString(); // 或者使用其他Date对象的方法来格式化时间
      }

    }
  }
}
</script>

<style scoped lang="less">
.container {
  margin: 0.7rem 4vw 0.7rem 4vw;
  padding: 1.6rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;

  .title {
    padding-bottom: 0.6rem;
    font-size: 0.86rem;
    color: #6E6045;
  }

  .total-number {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid #262526;
    margin-bottom: 1rem;

    .number {
      font-size: 1.43rem;
      font-weight: bold;
      color: #E7BD71;
    }

    .unit {
      padding-left: 0.75rem;
      font-size: 0.79rem;
      color: #987534;
    }
  }

  .other-number {
    margin-bottom: 2rem;
    display: flex;

    .number-item {
      flex: 1;
    }

    .line {
      float: left;
      width: 1px;
      height: 100%;
      background: #262526;
      margin-right: 1.11rem;
    }


    .number {
      font-size: 1.07rem;
      color: #F3C977;
    }
  }

  .list-cont {
    padding-bottom: 2rem;

    .list-item {
      overflow: hidden;

      .key {
        float: left;
        font-size: 0.93rem;
        color: #6E6045;
        line-height: 2.14rem;
      }

      .value {
        float: right;
        font-size: 0.86rem;
        color: #6E6045;
        line-height: 2.14rem;
      }

      .value2 {
        float: right;
        font-size: 1rem;
        line-height: 2.14rem;
        color: #E7BD71
      }
    }

    .tips {
      text-align: right;
      padding-top: 0.3rem;
      font-size: 0.86rem;
      color: #B5904E;
      line-height: 1.5rem;
    }
  }
}

._but {
  height: 2.86rem;
  line-height: 2.86rem;
  color: #000000;
  font-size: 1.14rem;
  text-align: center;
  background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
  border-radius: 1.43rem;
}

.type-cont {
  padding: 0 1.3rem;
  font-size: 1.29rem;
  color: #987534;

  .type-item {
    margin: 0 1.3rem 1rem;
    padding-top: 1.3rem;
    padding-bottom: 0.6rem;
    display: inline-block;
  }

  .active {
    font-weight: bold;
    color: #E7BD71;
    border-bottom: 0.14rem solid #E7BD71;
  }
}

.operate-cont {
  border-top: 1px solid #262526;
  display: flex;
  padding-top: 1.8rem;

  .operate-item {
    flex: 1;
    text-align: center;
    font-size: 0.86rem;
    color: #6E6045;

    a {
      color: #6E6045;
    }

    .icon {
      width: 2.14rem;
      height: 2.14rem;
      padding-bottom: 0.2rem;
    }
  }
}


.extract-cont {
  width: 85vw;
  max-height: 80vh;
  overflow-y: auto;
  padding: 1.2rem 1rem;
  box-sizing: border-box;
  background: #2D2D2D;
  border-radius: 1.07rem;

  .popup-title {
    text-align: center;
    color: #E7BD71;
    font-size: 1.14rem;
    margin-bottom: 1.8rem;

    .close-icon {
      float: right;
      position: relative;
      top: 0.3rem;
      width: 1.05rem;
    }
  }

  .tips {
    font-size: 0.86rem;
    color: #E64937;
    line-height: 1.07rem;
  }

  .info-cont {
    margin: 1rem 0;
    padding: 1.32rem;
    background: #1B1B1B;
    border-radius: 0.71rem;

    .info-item {
      overflow: hidden;
      padding: 0.5rem 0;

      .key {
        float: left;
        font-size: 0.93rem;
        color: #6E6045;
      }

      .value {
        float: right;
        font-size: 1.07rem;
        color: #E7BD71;
      }
    }
  }

  .balance-tips {
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 0.86rem;
    color: #625F5F;
    line-height: 1.5rem;

    a {
      color: #E7BD71;
    }
  }
}

</style>
